/* @tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

body {
  margin: 0;
}

.page {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

.laptop {
  max-height: 80%;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}
.right-content {
  padding-right: 60px;
}
.logo {
  max-height: 46px;
  margin-bottom: 50px;
}

.title {
  font-size: 70px;
  font-weight: bold;
  color: #ffffff;
}
.title-diagnosis {
  color: #4cf4da;
}
.pharmacy {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 3px;
  color: #ffffff;
}
.download {
  display: flex;
  align-items: center;
  justify-content: center;
}
.win-download {
  margin: 52px 30px 0 0;
  text-align: center;
}
.download-text {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 40px;
  cursor: pointer;
  background: #40aaf8;
  margin-bottom: 24px;
}
.download-title {
  padding: 14px 14px 14px 10px;
}
.win-fill {
  width: 30px;
  margin-left: 14px;
}
.mac-fill {
  width: 28px;
  margin-left: 14px;
}
.download-old-version {
  font-weight: bold;
  color: #4cf4da;
  cursor: pointer;
}
.download-old-version:hover {
  text-decoration: underline;
}

.download-text:hover {
  background: linear-gradient(183deg, #7f9af7, #1e87fa);
  box-shadow: 0px 5px 27px 0px #554af4;
}

.bottom {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  line-height: 1.6;
}

.archival {
  text-decoration: none;
  color: #ffffff;
}
